/* In your styles.css that's processed by Tailwind CSS */
.media-grid {
    column-count: 3;
    column-gap: 16px;
    width: 100%;
    padding: 25px;
    /* margin: 30px; */
  }
  
  .media-item {
    overflow: hidden;
    break-inside: avoid;
    margin-bottom: 16px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border-radius: 25px;
    padding: 4px;
    width: 100%;
    height: auto;
  }

  .media-item img,
.media-item video {
    border-radius: 21px;
    width: 100%;
    height: auto; /* Adjusts height to maintain aspect ratio */
    /* object-fit: cover;  */
}

.filter-options {
    @apply flex flex-row justify-between items-center;
}

.search-options {
    @apply w-80 h-16 flex-col justify-center items-center flex;
}

  /* For responsive behavior - ipad */
  @media only screen and (min-width: 600px) and (max-width: 992px) {
    .media-grid {
      column-count: 2;
    }

    .filter-options {
        @apply flex flex-row justify-between items-center;
    }

    .search-options {
        @apply w-80 h-16 flex-col justify-center items-center flex;
    }
  }

  /* For responsive behavior - mobile */
  @media only screen and (max-width: 600px) {
    .media-grid {
        column-count: 1;
      }

    .filter-options {
        @apply flex flex-row justify-between items-center my-6;
    }

    .search-options {
        @apply w-80 h-16 flex-col justify-center items-center flex;
    }
  }



  
  